import * as React from 'react';
import {css} from 'emotion';
import {CSSProperties} from "react";


function fontRenderer(text: string, fontFamily: string, color: string = "#000000", fontSize: number = 16, extra?: CSSProperties) {
    return <div style={{fontFamily, display: "inline-block", color, fontSize, ...extra}}>{text}</div>;
}

const poppins_bold = require('@res/fonts/Poppins-Bold.ttf');
const poppins_extrabold = require('@res/fonts/Poppins-ExtraBold.ttf');
const poppins_extralight = require('@res/fonts/Poppins-ExtraLight.ttf');
const poppins_light = require('@res/fonts/Poppins-Light.ttf');
const poppins_medium = require('@res/fonts/Poppins-Medium.ttf');
const poppins_regular = require('@res/fonts/Poppins-Regular.ttf');
const poppins_thin = require('@res/fonts/Poppins-Thin.ttf');

// Used for video calling.
// For name <-> font weight see: https://sharptype.co/typefaces/centra/centra-no2/
const centra_no2_black = require("@res/fonts/centrano2/CentraNo2-Black.ttf");
const centra_no2_black_italic = require("@res/fonts/centrano2/CentraNo2-BlackItalic.ttf");
const centra_no2_bold = require("@res/fonts/centrano2/CentraNo2-Bold.ttf");
const centra_no2_bold_italic = require("@res/fonts/centrano2/CentraNo2-BoldItalic.ttf");
const centra_no2_book = require("@res/fonts/centrano2/CentraNo2-Book.ttf");
const centra_no2_book_italic = require("@res/fonts/centrano2/CentraNo2-BookItalic.ttf");
const centra_no2_extra_bold = require("@res/fonts/centrano2/CentraNo2-Extrabold.ttf");
const centra_no2_extra_bold_italic = require("@res/fonts/centrano2/CentraNo2-ExtraboldItalic.ttf");
const centra_no2_hairline = require("@res/fonts/centrano2/CentraNo2-Hairline.ttf");
const centra_no2_hairline_italic = require("@res/fonts/centrano2/CentraNo2-HairlineItalic.ttf");
const centra_no2_light = require("@res/fonts/centrano2/CentraNo2-Light.ttf");
const centra_no2_light_italic = require("@res/fonts/centrano2/CentraNo2-LightItalic.ttf");
const centra_no2_medium = require("@res/fonts/centrano2/CentraNo2-Medium.ttf");
const centra_no2_medium_italic = require("@res/fonts/centrano2/CentraNo2-MediumItalic.ttf");
const centra_no2_thin = require("@res/fonts/centrano2/CentraNo2-Thin.ttf");
const centra_no2_thin_italic = require("@res/fonts/centrano2/CentraNo2-ThinItalic.ttf");


// Used for the shopping list web app
const sf_pro_black = require("@res/fonts/san_francisco/SF-Pro-Display-Black.ttf");
const sf_pro_black_italic = require("@res/fonts/san_francisco/SF-Pro-Display-BlackItalic.ttf");
const sf_pro_bold = require("@res/fonts/san_francisco/SF-Pro-Display-Bold.ttf");
const sf_pro_bold_italic = require("@res/fonts/san_francisco/SF-Pro-Display-BoldItalic.ttf");
const sf_pro_heavy = require("@res/fonts/san_francisco/SF-Pro-Display-Heavy.ttf");
const sf_pro_heavy_italic = require("@res/fonts/san_francisco/SF-Pro-Display-HeavyItalic.ttf");
const sf_pro_light = require("@res/fonts/san_francisco/SF-Pro-Display-Light.ttf");
const sf_pro_light_italic = require("@res/fonts/san_francisco/SF-Pro-Display-Light.ttf");
const sf_pro_medium = require("@res/fonts/san_francisco/SF-Pro-Display-Medium.ttf");
const sf_pro_medium_italic = require("@res/fonts/san_francisco/SF-Pro-Display-MediumItalic.ttf");
const sf_pro_regular = require("@res/fonts/san_francisco/SF-Pro-Display-Regular.ttf");
const sf_pro_regular_italic = require("@res/fonts/san_francisco/SF-Pro-Display-RegularItalic.ttf");
const sf_pro_semibold = require("@res/fonts/san_francisco/SF-Pro-Display-Semibold.ttf");
const sf_pro_semibold_italic = require("@res/fonts/san_francisco/SF-Pro-Display-SemiboldItalic.ttf");
const sf_pro_thin = require("@res/fonts/san_francisco/SF-Pro-Display-Thin.ttf");
const sf_pro_thin_italic = require("@res/fonts/san_francisco/SF-Pro-Display-ThinItalic.ttf");
const sf_pro_ultralight = require("@res/fonts/san_francisco/SF-Pro-Display-Ultralight.ttf");
const sf_pro_ultralight_italic = require("@res/fonts/san_francisco/SF-Pro-Display-UltralightItalic.ttf");

export const globalStyleGuide = css`
  @font-face {
    font-family: poppins_bold;
    src: url(${poppins_bold})
  }
  @font-face {
    font-family: poppins_extrabold;
    src: url(${poppins_extrabold})
  }
  @font-face {
    font-family: poppins_extralight;
    src: url(${poppins_extralight})
  }
  @font-face {
    font-family: poppins_light;
    src: url(${poppins_light})
  }
  @font-face {
    font-family: poppins_medium;
    src: url(${poppins_medium})
  }
  @font-face {
    font-family: poppins_regular;
    src: url(${poppins_regular})
  }
  @font-face {
    font-family: poppins_thin;
    src: url(${poppins_thin})
  }

  @font-face {
    font-family: centra_no2_black;
    src: url(${centra_no2_black});
  }
  @font-face {
    font-family: centra_no2_black_italic;
    src: url(${centra_no2_black_italic});
  }
  @font-face {
    font-family: centra_no2_bold;
    src: url(${centra_no2_bold});
  }
  @font-face {
    font-family: centra_no2_bold_italic;
    src: url(${centra_no2_bold_italic});
  }
  @font-face {
    font-family: centra_no2_book;
    src: url(${centra_no2_book});
  }
  @font-face {
    font-family: centra_no2_book_italic;
    src: url(${centra_no2_book_italic});
  }
  @font-face {
    font-family: centra_no2_extra_bold;
    src: url(${centra_no2_extra_bold});
  }
  @font-face {
    font-family: centra_no2_extra_bold_italic;
    src: url(${centra_no2_extra_bold_italic});
  }
  @font-face {
    font-family: centra_no2_hairline;
    src: url(${centra_no2_hairline});
  }
  @font-face {
    font-family: centra_no2_hairline_italic;
    src: url(${centra_no2_hairline_italic});
  }
  @font-face {
    font-family: centra_no2_light;
    src: url(${centra_no2_light});
  }
  @font-face {
    font-family: centra_no2_light_italic;
    src: url(${centra_no2_light_italic});
  }
  @font-face {
    font-family: centra_no2_medium;
    src: url(${centra_no2_medium});
  }
  @font-face {
    font-family: centra_no2_medium_italic;
    src: url(${centra_no2_medium_italic});
  }
  @font-face {
    font-family: centra_no2_thin;
    src: url(${centra_no2_thin});
  }
  @font-face {
    font-family: centra_no2_thin_italic;
    src: url(${centra_no2_thin_italic});
  }

  @font-face {
    font-family: sf_pro_black;
    src: url(${sf_pro_black});
  }
  @font-face {
    font-family: sf_pro_black_italic;
    src: url(${sf_pro_black_italic});
  }
  @font-face {
    font-family: sf_pro_bold;
    src: url(${sf_pro_bold});
  }
  @font-face {
    font-family: sf_pro_bold_italic;
    src: url(${sf_pro_bold_italic});
  }
  @font-face {
    font-family: sf_pro_heavy;
    src: url(${sf_pro_heavy});
  }
  @font-face {
    font-family: sf_pro_heavy_italic;
    src: url(${sf_pro_heavy_italic});
  }
  @font-face {
    font-family: sf_pro_light;
    src: url(${sf_pro_light});
  }
  @font-face {
    font-family: sf_pro_light_italic;
    src: url(${sf_pro_light_italic});
  }
  @font-face {
    font-family: sf_pro_medium;
    src: url(${sf_pro_medium});
  }
  @font-face {
    font-family: sf_pro_medium_italic;
    src: url(${sf_pro_medium_italic});
  }
  @font-face {
    font-family: sf_pro_regular;
    src: url(${sf_pro_regular});
  }
  @font-face {
    font-family: sf_pro_regular_italic;
    src: url(${sf_pro_regular_italic});
  }
  @font-face {
    font-family: sf_pro_semibold;
    src: url(${sf_pro_semibold});
  }
  @font-face {
    font-family: sf_pro_semibold_italic;
    src: url(${sf_pro_semibold_italic});
  }
  @font-face {
    font-family: sf_pro_thin;
    src: url(${sf_pro_thin});
  }
  @font-face {
    font-family: sf_pro_thin_italic;
    src: url(${sf_pro_thin_italic});
  }
  @font-face {
    font-family: sf_pro_ultralight;
    src: url(${sf_pro_ultralight});
  }
  @font-face {
    font-family: sf_pro_ultralight_italic;
    src: url(${sf_pro_ultralight_italic});
  }

`;

export const FONTS = {
    poppins_bold: (text: string, color?: string, size?: number) => fontRenderer(text, 'poppins_bold', color, size),
    poppins_extrabold: (text: string, color?: string, size?: number) => fontRenderer(text, 'poppins_extrabold', color, size),
    poppins_extralight: (text: string, color?: string, size?: number) => fontRenderer(text, 'poppins_extralight', color, size),
    poppins_light: (text: string, color?: string, size?: number) => fontRenderer(text, 'poppins_light', color, size),
    poppins_medium: (text: string, color?: string, size?: number) => fontRenderer(text, 'poppins_medium', color, size),
    poppins_regular: (text: string, color?: string, size?: number) => fontRenderer(text, 'poppins_regular', color, size),
    poppins_thin: (text: string, color?: string, size?: number) => fontRenderer(text, 'poppins_thin', color, size),

    centra_no2_black: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_black', color, size, extra),
    centra_no2_black_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_black_italic', color, size, extra),
    centra_no2_bold: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_bold', color, size, extra),
    centra_no2_bold_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_bold_italic', color, size, extra),
    centra_no2_book: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_book', color, size, extra),
    centra_no2_book_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_book_italic', color, size, extra),
    centra_no2_extra_bold: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_extra_bold', color, size, extra),
    centra_no2_extra_bold_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_extra_bold_italic', color, size, extra),
    centra_no2_hairline: (text: string, color?: string, size?: number) => fontRenderer(text, 'centra_no2_hairline', color, size),
    centra_no2_hairline_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_hairline_italic', color, size, extra),
    centra_no2_light: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_light', color, size, extra),
    centra_no2_light_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_light_italic', color, size, extra),
    centra_no2_medium: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_medium', color, size, extra),
    centra_no2_medium_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_medium_italic', color, size, extra),
    centra_no2_thin: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_thin', color, size, extra),
    centra_no2_thin_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'centra_no2_thin_italic', color, size, extra),

    sf_pro_black: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_black', color, size, extra),
    sf_pro_black_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_black_italic', color, size, extra),
    sf_pro_bold: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_bold', color, size, extra),
    sf_pro_bold_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_bold_italic', color, size, extra),
    sf_pro_heavy: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_heavy', color, size, extra),
    sf_pro_heavy_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_heavy_italic', color, size, extra),
    sf_pro_light: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_light', color, size, extra),
    sf_pro_light_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_light_italic', color, size, extra),
    sf_pro_medium: (text: string, color?: string, size?: number) => fontRenderer(text, 'sf_pro_medium', color, size),
    sf_pro_medium_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_medium_italic', color, size, extra),
    sf_pro_regular: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_regular', color, size, extra),
    sf_pro_regular_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_regular_italic', color, size, extra),
    sf_pro_semibold: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_semibold', color, size, extra),
    sf_pro_semibold_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_semibold_italic', color, size, extra),
    sf_pro_thin: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_thin', color, size, extra),
    sf_pro_thin_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_thin_italic', color, size, extra),
    sf_pro_ultralight: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_ultralight', color, size, extra),
    sf_pro_ultralight_italic: (text: string, color?: string, size?: number, extra?: CSSProperties) => fontRenderer(text, 'sf_pro_ultralight_italic', color, size, extra),
};
