import * as React from "react";
import {FONTS} from "@res/fonts";
import {
    cancelButtonStyle, checkbox_outline_black,
    COLOR_BLACK,
    COLOR_LIST_ITEM_BLUE,
    COLOR_WHITE, deleteButtonStyle, icon__checkbox_ticked,
    itemStyle,
    popupContainerStyle,
    popupContentStyle
} from "./style";

type Props = {
    onDelete: () => void;
    onCancel: () => void;
}

type State = {
    confirmDialogDisabled: boolean;
}

export class ConfirmationPopup extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            confirmDialogDisabled: false
        };
    }
    render() {
        return (
            <div className={popupContainerStyle}>
                <div className={popupContentStyle}>
                    <h1 style={{ fontFamily: "Arial Rounded MT Bold", fontSize: "24px", fontStyle: "normal", fontWeight: "400", lineHeight: "23px", marginTop: 0}}>
                        Delete items?
                            {FONTS.sf_pro_regular("This will delete the checked items from your list - both on your phone and on ElliQ",
                                COLOR_BLACK,
                                18,
                                { lineHeight: "24px", marginTop: "16px"}
                            )}
                    </h1>
                    <label className={itemStyle}>
                        <input
                            style={{ display: "none" }}
                            type="checkbox"
                            onChange={() => this.handleConfirmationPromptToggle()}
                        />
                        { this.state.confirmDialogDisabled ?
                            <div style={{ margin: "0" }}>
                                <img style={{ verticalAlign: "middle", cursor: "pointer" }} src={icon__checkbox_ticked}></img>
                            </div> :
                            <div style={{ margin: "0" }}>
                                <img style={{ verticalAlign: "middle", cursor: "pointer" }} src={checkbox_outline_black}></img>
                            </div>
                        }
                        <span style={{ cursor: "pointer", marginLeft: "8px" }}>
                                {FONTS.sf_pro_semibold("Don’t show again", COLOR_BLACK, 18)}
                            </span>
                    </label>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "80px" }}>
                        <button className={cancelButtonStyle} onClick={() => {this.props.onCancel();}}>
                            {FONTS.sf_pro_semibold("Cancel", COLOR_LIST_ITEM_BLUE, 18)}
                        </button>
                        <button className={deleteButtonStyle} onClick={() => {
                            if (this.state.confirmDialogDisabled)
                                localStorage.setItem("shop_confirm_dialog_disabled", "true")
                            this.props.onDelete();
                        }}>
                            {FONTS.sf_pro_medium("Delete", COLOR_WHITE, 18)}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    handleConfirmationPromptToggle = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                confirmDialogDisabled: !prevState.confirmDialogDisabled
            };
        });
    };
}