export const config = {
	ThunderstormModule: {
		appName: "Messaging"
	},
	XhrHttpModule: {
		origin: "https://us-central1-ir-q-messaging.cloudfunctions.net/api",
		timeout: 30000,
		compress: true
	},
	ServiceWorker: {
		firebaseVersion: "8.2.6"
	},
	EnvironmentModule: {
		envName: "dev",
		kasperoUrl: "https://login.intuitionrobotics.com"
	},
	FirebaseModule: {
		local: {
			apiKey: "AIzaSyDZJQXu36HbMTbdQrBfmTAkRbLxGDx6AS8",
			authDomain: "ir-q-messaging.firebaseapp.com",
			databaseURL: "https://ir-q-messaging-default-rtdb.firebaseio.com",
			projectId: "ir-q-messaging",
			storageBucket: "ir-q-messaging.appspot.com",
			messagingSenderId: "59944026744",
			appId: "1:59944026744:web:be9e151957e2ff1111a38d",
			measurementId: "G-EF3M0405QV"
		}
	},
	PushPubSubModule: {
		publicKeyBase64: "BOq3GGsY7mR4_4dJsb3OWuQjs60ueYMoqlzN66w0HXwt3enoIKfl9IHYKQrproOSk0p0tQ2UIxCApc47HHpq0ZY",
		swFileName: 'sw.js'
	},
	ForceUpgrade: {
		assertVersionUrl: "/v1/version/assert"
	},
	ServerApiModule: {
		serverApiUrl: "https://elliq-api-server-prod.p.elliq.co/api"
	},
	SupportModule: {
		supportUrls: ["https://ir-q-support.elliq.co", "https://support.elliq.co"]
	}
};
