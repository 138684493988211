import * as React from "react";
import {checkbox_outline_blue, checkedLabelStyle, icon__checkbox_ticked, itemStyle, labelStyle} from "./style";

type Props = {
    name: string;
    onClick: (name: string, isChecked: boolean) => void;
}

type State = {
    checked: boolean;
}

export class ListItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            checked: false
        };
    }
    render() {
        const name = this.props.name;
        return <label className={itemStyle}>
            <input
                style={{ display: "none" }}
                type="checkbox"
                onChange={() => this.handleItemToggle()}
                checked={this.state.checked}
            />
            {this.state.checked ?
                <div style={{ margin: "0" }}>
                    <img style={{ margin: "3px 3px 3px 12px", verticalAlign: "middle", cursor: "pointer" }} src={icon__checkbox_ticked}></img>
                </div>
                :
                <div style={{ margin: "0" }}>
                    <img style={{ margin: "3px 3px 3px 12px", verticalAlign: "middle", cursor: "pointer" }} src={checkbox_outline_blue}></img>
                </div>
            }
            <span className={`${labelStyle} ${this.state.checked && checkedLabelStyle}`}>
                {name.charAt(0).toUpperCase() + name.slice(1)}
            </span>
        </label>;
    }

    handleItemToggle = () => {
        this.setState({ checked: !this.state.checked }, () => this.props.onClick(this.props.name, this.state.checked));
    };
}