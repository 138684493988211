// tslint:disable:no-import-side-effect
import * as React from "react";
import './res/styles/styles.scss';

import {ErrorHandler_Dispatch, Thunder, WrapperProps, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Shop} from "./shop/Shop";
import {ServerApiModule} from "./shop/ServerApiModule";

new Thunder()
	.setConfig(require("./config").config)
	.setMainApp((props:WrapperProps) => <Shop {...props}/>)
	.setModules(ServerApiModule)
	.build(() => {
		XhrHttpModule.setErrorHandlers([ErrorHandler_Dispatch]);
	});


