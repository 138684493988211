import {Module} from "@intuitionrobotics/ts-common";

type Config = {
    serverApiUrl: string
}

class ServerApiModule_Class extends Module<Config> {
    private jwt: string | null = null;
    private shopJwtKey: string = "shop-t";


    async fetchShoppingList(unitId: string) {
        const jwt = this.getJwt();
        return fetch(`${this.config.serverApiUrl}/shoppingList/getList/${unitId}`, {
            method: 'get',
            headers: {'Content-Type': 'application/json', "authorization": `Bearer ${jwt}`}
        })
    }

    async handleDelete(unitId: string, items: string[]) {
        return fetch(`${this.config.serverApiUrl}/shoppingList/removeItems/${unitId}`, {
            method: 'post',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${(this.getJwt())}`},
            body: JSON.stringify({items})
        })
    }


    setJwt(jwt: string) {
        sessionStorage.setItem(this.shopJwtKey, jwt);
        this.jwt = jwt;
    }

    getJwt() {
        if (!this.jwt)
            this.jwt = sessionStorage.getItem(this.shopJwtKey);
        return this.jwt;
    }

}

export const ServerApiModule = new ServerApiModule_Class("ServerApiModule");
