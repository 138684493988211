import * as React from "react";
import {COLOR_LIST_BACKGROUND_BEIGE, COLOR_LIST_LINE_LIGHT_BLUE, headingStyle, labelStyle} from "./style";
import {DeleteButton} from "./DeleteButton";
import {ListItem} from "./ListItem";
import {BrowserHistoryModule} from "@intuitionrobotics/thunderstorm/frontend";
import {ServerApiModule} from "./ServerApiModule";

export type Item = {
    name: string
    checked: boolean
}

type State = {
    items: Item[],
    loading: boolean,
    unitId?: string
}

export class Shop
    extends React.Component<{}, State> {

    constructor(props: {}) {
        super(props);

        const queryParams = BrowserHistoryModule.getQueryParams();
        BrowserHistoryModule.removeQueryParam("t")
        const jwt = queryParams["t"] as string;
        if (jwt)
            ServerApiModule.setJwt(jwt);

        this.state = {
            items: [],
            loading: true,
            unitId: queryParams["unitId"]
        };
        this.fetchShoppingList().catch(console.error);
    }

    private async fetchShoppingList() {
        if (!this.state.unitId) {
            console.error("No unit Id set");
            return this.setState({loading: false});
        }

        try {
            const response = await ServerApiModule.fetchShoppingList(this.state.unitId);
            const res : string[] = await response.json();
            this.setState({
                items: res.map(i => ({name: i, checked: false})),
                loading: false
            })
        } catch (e) {
            console.error("Error retrieving items", e);
            this.setState({loading: false})
        }
    }

    renderItem(item: Item) {
        return <ListItem name={item.name} onClick={(name, checked) => {
            this.updateListState(name, checked)
        }}/>
    }

    renderList() {
        if (!this.state.unitId)
            return <span className={labelStyle}>Something went wrong, please try again</span>
        if (this.state.items.length < 1)
            return <span className={labelStyle}>No items</span>

        return this.state.items.map((i: Item) =>
            <li key={i.name} style={{borderBottom: `1px solid ${COLOR_LIST_LINE_LIGHT_BLUE}`, padding: "10px 0"}}>
                {this.renderItem(i)}
            </li>
        );
    }

    renderDeleteButton() {
        const checkedItems = this.state.items.filter(item => item.checked);
        if (checkedItems.length > 0) {
            return <DeleteButton selectedItemsCount={checkedItems.length} onDelete={() => this.handleDelete()}/>;
        }
        return null;
    }

    render() {
        if (this.state.loading)
            return <span className={labelStyle}>Loading...</span>

        return <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100dvh",
            backgroundColor: COLOR_LIST_BACKGROUND_BEIGE
        }}>
            <h1 className={headingStyle}>My shopping list</h1>
            <ul style={{listStyle: "none", padding: 0, marginTop: 40}}>
                {
                    this.renderList()
                }
            </ul>
            {this.renderDeleteButton()}
        </div>
    }


    updateListState = (name: string, isChecked: boolean) => {
        const newItems = [...this.state.items];
        for (const item of newItems) {
            if (item.name === name) {
                item.checked = isChecked;
            }
        }
        this.setState({items: newItems});
    };

    handleDelete = async () => {
        if (!this.state.unitId)
            return;

        try {
            await ServerApiModule.handleDelete(this.state.unitId, this.state.items.filter(item => item.checked).map(i => i.name))
            this.setState((prevState) => ({
                ...prevState,
                items: prevState.items.filter(item => !item.checked)
            }));
        } catch (e) {
            // probably need to reflect in state somehow (error handling)
            console.error("Error deleting items", e)
        }
    }
}
