import * as React from "react";
import {deleteSelectedButtonStyle, icon__trash_can} from "./style";
import {ConfirmationPopup} from "./ConfirmationPopup";

type Props = {
    selectedItemsCount: number;
    onDelete: () => void;
}

type State = {
    showConfirmationPrompt: boolean;
}

export class DeleteButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showConfirmationPrompt: false
        };
    }

    render() {
        const isDisabled = localStorage.getItem("shop_confirm_dialog_disabled");
        return (
            <>
                <div style={{flex: 1}}/>
                <button className={deleteSelectedButtonStyle} onClick={() => {
                    isDisabled === null || isDisabled === "false" ?
                        this.setState({ showConfirmationPrompt: true }) :
                        this.props.onDelete()}}>
                    <img style={{marginRight: "16px"}} src={icon__trash_can} alt={`Delete selected`}/>
                    Delete selected ({this.props.selectedItemsCount})
                </button>
                {this.state.showConfirmationPrompt &&
                    <ConfirmationPopup
                        onCancel={() => this.setState({ showConfirmationPrompt: false })}
                        onDelete={() => {
                            this.setState({ showConfirmationPrompt: false })
                            this.props.onDelete()
                        }}
                    />}
            </>
        );
    }
}