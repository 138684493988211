// Color constants
import * as emotion from "emotion";

export const COLOR_WHITE = "#FFFFFF";
export const COLOR_BLACK = "#000000";
export const COLOR_LIST_BACKGROUND_BEIGE = "#F9F6F2";
export const COLOR_LIST_LINE_LIGHT_BLUE = "#D0E5F9";
export const COLOR_LIST_ITEM_BLUE = "#0C3372";
export const COLOR_LIST_ITEM_CHECKED_GREY = "#828DA0";
export const COLOR_DELETE_BUTTON_RED = "#BB0000";



export const icon__trash_can = require("@res/icons/icon__trash_can.svg");
export const checkbox_outline_blue = require("@res/icons/icon__checkbox_outline_blue.svg");
export const checkbox_outline_black = require("@res/icons/icon__checkbox_outline_black.svg");
export const icon__checkbox_ticked = require("@res/icons/icon__checkbox_ticked.svg");


export const headingStyle = emotion.css`
    color: ${COLOR_LIST_ITEM_BLUE};
    font-family: "Arial Rounded MT Bold", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-left: 12px;
    margin-bottom: 0;
    line-height: 24px;
    text-decoration-line: underline;
`;

export const itemStyle = emotion.css`
    display: flex;
    align-items: center;
`;

export const labelStyle = emotion.css`
    color: ${COLOR_LIST_ITEM_BLUE};
    cursor: pointer;
    font-family: Arial Rounded MT Bold;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-left: 20px;
`;

export const checkedLabelStyle = emotion.css`
    color: ${COLOR_LIST_ITEM_CHECKED_GREY};
    text-decoration-line: line-through;
`;

export const deleteSelectedButtonStyle = emotion.css`
    background-color: ${COLOR_DELETE_BUTTON_RED};
    color: ${COLOR_WHITE};
    text-align: center;
    font-family: Arial Rounded MT Bold;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    padding: 12px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 12px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
`;

export const popupContainerStyle = emotion.css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`;

export const popupContentStyle = emotion.css`
    background: ${COLOR_WHITE};
    padding: 24px;
    border-radius: 10px;
    margin: 0 32px;
`;

export const cancelButtonStyle = emotion.css`
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: none;
    line-height: 23px;
`;

export const deleteButtonStyle = emotion.css`
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: ${COLOR_LIST_ITEM_BLUE};
    line-height: 23px;
`;